<template>
  <div class="admin-background">
    <h2 class="text--dark" style="margin-bottom: 30px">Välj bakgrundsbild</h2>
    <div class="f-r" style="margin-bottom: 30px">
      <button class="button" @click="$refs.fileInput.click()">Ladda upp bild</button>
      <input type="file" ref="fileInput" style="display: none;"  @change="(e) => addImage(e, false)">
    </div>
    <p class="text--dark" style="margin-bottom: 10px">Nuvarande bakgrundsbild</p>
    <img class="current-background" style="margin-bottom: 10px" :src="$store.state.backgroundImage" />
    <p class="text--dark">Senast ändrad: <span class="text-bold">{{ $store.state.backgroundLastUpdated }}</span></p>
  </div>
</template>

<script>
import processImage from '../../store/processImage'

export default {
  data() {
    return {
      loadingImage: false
    }
  },
  methods: {
    async addImage(event) {
      this.loadingImage = true

      // Remove old image
      const oldImage = this.$store.state.backgroundImage
      await this.$store.dispatch('removeFile', { url: oldImage })

      const file = await processImage(event.target.files[0])

      const url = await this.$store.dispatch('uploadFile', { folder: 'background-image', file: file, uid: event.timeStamp.toString() })
      const currentDateString = new Date().toLocaleDateString('sv-SE', { year: 'numeric', month: 'long', day: 'numeric' })
      await this.$store.dispatch('updateBackgroundImage', { 'url': url, 'lastUpdated': currentDateString })
      this.loadingImage = false
    }
  }
};
</script>

<style scoped>
.admin-background {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}

.current-background {
  width: 40%;
  height: auto;
  border-radius: 10px;
}
</style>
